import './App.css';
import Calculator from './main/Calculator';

function App() {
  return (
    <>
    <h1>Calculator</h1>
    <Calculator/>
    </>
  );
}

export default App;
